import React, { useState } from "react";
import { useAuthStore } from "../../stores/auth";
import { useSelectedPaymentMethodId } from "../../stores/useSelectedPaymentMethodId";
import { usePaymentMethods } from "../../stores/paymentMethods";
import { Stripe } from "@stripe/stripe-js";

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type PaymentMethod = ArrayElement<
  ReturnType<typeof usePaymentMethods>["paymentMethods"]
>;

export const PaySomething = ({
  stripePromise,
  returnUrl,
}: {
  stripePromise: Promise<Stripe | null>;
  returnUrl: string;
}) => {
  const selectedPaymentMethodId = useSelectedPaymentMethodId((s) => s.id);
  const authInfo = useAuthStore((s) => s.info);
  const { paymentMethods } = usePaymentMethods();
  const [amount, setAmount] = useState(1);
  const [offSession, setOffSession] = useState(false);
  const [response, setResponse] = useState<{ id: string } | null>(null);
  const selectedPaymentMethod = paymentMethods.reduce((result, current) => {
    if (current.id === selectedPaymentMethodId) return current;
    return result;
  }, null as PaymentMethod | null);

  const canPay = authInfo?.customerId && selectedPaymentMethod;

  const handlePay = async () => {
    if (!canPay) return;
    setResponse(null);

    const response = await fetch("/api/charge", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        customerId: authInfo.customerId,
        paymentMethodId: selectedPaymentMethod.id,
        amount,
        offSession,
        return_url: returnUrl,
      }),
    });
    const responseBody = await response.json();
    if (response.status >= 400) {
      alert(responseBody.message);
      return;
    }

    if (responseBody.status === "requires_action") {
      stripePromise.then(async (stripe) => {
        if (!stripe) return;
        const { error } = await stripe.handleNextAction({
          clientSecret: responseBody.client_secret,
        });

        if (error) {
          // Show error from Stripe.js in payment form
        } else {
          // Actions handled, show success message
        }
      });
    }
    setResponse(responseBody);
  };

  return (
    <>
      <div className="box">
        <h3>Pay Something</h3>
        <p>
          <input
            type="checkbox"
            id="off_session"
            checked={offSession}
            onChange={(e) => setOffSession(e.target.checked)}
          />
          <label htmlFor="off_session">Off session</label>
          <br />

          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
          />
          <br />

          <button onClick={handlePay} disabled={!canPay}>
            Pay
          </button>
        </p>
        {response && (
          <pre style={{ overflowX: "scroll" }}>
            {JSON.stringify(response, null, 2)}
          </pre>
        )}
      </div>
    </>
  );
};
