import React, { useState } from "react";
import {
  useStripe,
  useElements,
  IdealBankElement,
} from "@stripe/react-stripe-js";

import IdealBankSection from "./IdealBankSection";
import { StripeIdealBankElement } from "@stripe/stripe-js";
import { useAuthStore } from "@/app/stores/auth";

export type API = "payment" | "setup";

export default function CustomIdealForm() {
  const stripe = useStripe();
  const elements = useElements();
  const authInfo = useAuthStore((s) => s.info);

  const [chosenApi, setChosenApi] = useState<API>("payment");

  const changeApi = (event: any) => {
    setChosenApi(event.target.value);
  }

  const handlePaymentIntent = async (clientSecret: string, idealBank: StripeIdealBankElement, name: string, email: string) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    return await stripe.confirmIdealPayment(clientSecret, {
      payment_method: {
        ideal: idealBank,
        billing_details: {
          name: name,
          email: email,
        },
      },
      return_url: "https://pay-wallets-poc.dev.envs.lookiero.tech/#/custom-ideal",
    });
  }

  const handleSetupIntent = async (clientSecret: string, idealBank: StripeIdealBankElement, name: string, email: string) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    return await stripe.confirmIdealSetup(clientSecret, {
      payment_method: {
        ideal: idealBank,
        billing_details: {
          name: name,
          email: email,
        },
      },
      return_url: "https://pay-wallets-poc.dev.envs.lookiero.tech/#/custom-ideal",
    });
  }

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const idealBank = elements.getElement(
      IdealBankElement
    ) as StripeIdealBankElement;

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName: any = event.target["accountholder-name"];
    const accountholdeeEmail: any = event.target["accountholder-email"];

    const response = await fetch(
      `/api/ideal/${chosenApi}-intent?customer_id=${authInfo!.customerId}`,
      {
        method: "GET",
      }
    );

    const body = await response.json();

    const error: any = chosenApi === "payment" 
      ? await handlePaymentIntent(body.client_secret, idealBank, accountholderName.value, accountholdeeEmail.value) 
      : await handleSetupIntent(body.client_secret, idealBank, accountholderName.value, accountholdeeEmail.value)

    if (error) {
      // Show error to your customer.
      console.log(error.message);
    }

    // Otherwise the customer will be redirected away from your
    // page to complete the payment with their bank.
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Choose between Payment Intent or Setup Intent
          <select value={chosenApi} onChange={changeApi}>
            <option value="payment">Payment Intent</option>
            <option value="setup">Setup Intent</option>
          </select>
        </label>
      </div>
      <div className="form-row">
        <label>
          Name
          <input name="accountholder-name" placeholder="Cavaro Alrretero" required />
        </label>
      </div>
      <div className="form-row">
        <label>
          Email
          <input name="accountholder-email" placeholder="c.alrretero@lookiero.com" required />
        </label>
      </div>
      <div className="form-row">
        <IdealBankSection />
      </div>
      <button type="submit" disabled={!stripe}>
        Submit Payment
      </button>
    </form>
  );
}
