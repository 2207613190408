import { useAuthStore } from "@/app/stores/auth";
import {
  Card,
  PaymentMethod,
  PayPal,
  Sepa_debit,
  usePaymentMethods,
} from "@/app/stores/paymentMethods";
import React from "react";
import { useEffect } from "react";
import { useSelectedPaymentMethodId } from "../stores/useSelectedPaymentMethodId";

const PAYMENT_METHOD_LOGOS: Record<string, string | undefined> = {
  visa: "https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/logo/visa.svg",
  mastercard:
    "https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/logo/mastercard.svg",
  paypal:
    "https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/logo/paypal.svg",
  ideal: "https://www.ideal.nl/img/logo/ideal-logo.svg",
  sepa_debit: "https://www.ideal.nl/img/logo/ideal-logo.svg",
  default:
    "https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/flat/code.svg",
};
const getPaymentMethodLogo = (brand: string) => {
  return PAYMENT_METHOD_LOGOS[brand] || PAYMENT_METHOD_LOGOS["default"];
};

export const ExistingPaymentMethods = () => {
  const { paymentMethods, refresh: refreshPaymentMethods } =
    usePaymentMethods();
  const selectedPaymentMethodId = useSelectedPaymentMethodId((s) => s.id);
  const authInfo = useAuthStore((s) => s.info);

  useEffect(() => {
    useSelectedPaymentMethodId.setState({ id: null });
  }, []);

  const handleDelete = async (paymentMethodId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const customerId = authInfo!.customerId;
    const response = await fetch(
      `/api/payment_methods?customer_id=${customerId}&payment_method_id=${paymentMethodId}`,
      { method: "DELETE" }
    );
    const responseBody = await response.json();
    if (response.status >= 400) {
      alert(responseBody.message);
      return;
    }
    refreshPaymentMethods();
  };

  return (
    <div className="box">
      <h3>
        Existing Payment Methods{" "}
        <button onClick={refreshPaymentMethods}>Refresh</button>
      </h3>

      <form>
        {paymentMethods
          .sort((a, b) => b.created - a.created)
          .map((pm) => {
            return paymentMethodMap[pm.type](
              handleDelete,
              pm as PaymentMethod,
              pm.id === selectedPaymentMethodId
            );
          })}
      </form>
    </div>
  );
};

const paymentMethodMap = {
  card: (
    handleDelete: (paymentMethodId: string) => Promise<void>,
    pm: PaymentMethod,
    selected: boolean
  ) =>
    CardPaymentMethod({
      handleDelete,
      pm: pm as Card,
      selected,
    }),
  paypal: (
    handleDelete: (paymentMethodId: string) => Promise<void>,
    pm: PaymentMethod,
    selected: boolean
  ) =>
    PayPalPaymentMethod({
      handleDelete,
      pm: pm as PayPal,
      selected,
    }),
  sepa_debit: (
    handleDelete: (paymentMethodId: string) => Promise<void>,
    pm: PaymentMethod,
    selected: boolean
  ) =>
    SepaDebitlPaymentMethod({ handleDelete, pm: pm as Sepa_debit, selected }),
};

const CardPaymentMethod = ({
  handleDelete,
  pm,
  selected,
}: {
  handleDelete: (id: string) => void;
  pm: Card;
  selected: boolean;
}) => {
  return (
    <div className="credit-card">
      <input
        type="radio"
        id={pm.id}
        name="pm"
        onChange={(e) =>
          useSelectedPaymentMethodId.setState({ id: e.target.id })
        }
        checked={selected}
      />
      <label className="info" htmlFor={pm.id}>
        {<img src={getPaymentMethodLogo(pm.card.brand)} alt="" />}
        <div className="digits">
          <span className="fill">###</span>
          <span className="tail">{pm.card.last4}</span>
        </div>
        <div className="expiration">
          <span>
            {pm.card.exp_month.toString().padStart(2, "0")}/{pm.card.exp_year}
          </span>
        </div>
      </label>
      <div className="meta">
        <ul>
          <li>id: {pm.id}</li>
          <li>
            wallet: {pm.card.wallet?.type || "none"}{" "}
            {pm.metadata.pay_wallets_poc_origin ===
            "direct google pay integration"
              ? "(direct)"
              : ""}
          </li>
        </ul>
        <button type="button" onClick={() => handleDelete(pm.id)}>
          X
        </button>
      </div>
    </div>
  );
};

const PayPalPaymentMethod = ({
  handleDelete,
  pm,
  selected,
}: {
  handleDelete: (id: string) => void;
  pm: PayPal;
  selected: boolean;
}) => {
  return (
    <div className="credit-card">
      <input
        type="radio"
        id={pm.id}
        name="pm"
        onChange={(e) =>
          useSelectedPaymentMethodId.setState({ id: e.target.id })
        }
        checked={selected}
      />
      <label className="info" htmlFor={pm.id}>
        {<img src={getPaymentMethodLogo(pm.type)} alt="" />}
        <div className="digits">
          <span className="fill">###</span>
          <span className="tail">{pm.paypal.verified_email}</span>
        </div>
      </label>
      <div className="meta">
        <ul>
          <li>id: {pm.id}</li>
          <li>fingerprint: {pm.paypal.fingerprint}</li>
        </ul>
        <button type="button" onClick={() => handleDelete(pm.id)}>
          X
        </button>
      </div>
    </div>
  );
};

const SepaDebitlPaymentMethod = ({
  handleDelete,
  pm,
  selected,
}: {
  handleDelete: (id: string) => void;
  pm: Sepa_debit;
  selected: boolean;
}) => {
  return (
    <div className="credit-card">
      <input
        type="radio"
        id={pm.id}
        name="pm"
        onChange={(e) =>
          useSelectedPaymentMethodId.setState({ id: e.target.id })
        }
        checked={selected}
      />
      <label className="info" htmlFor={pm.id}>
        {<img src={getPaymentMethodLogo(pm.type)} alt="" />}
        <div className="digits">
          <span className="fill">###</span>
          <span className="tail">{pm.sepa_debit.last4}</span>
        </div>
        <div className="expiration">
          <span>
            {pm.sepa_debit.bank_code} / {pm.sepa_debit.branch_code} /{" "}
            {pm.sepa_debit.country}
          </span>
        </div>
      </label>
      <div className="meta">
        <ul>
          <li>id: {pm.id}</li>
          <li>fingerprint: {pm.sepa_debit.fingerprint}</li>
        </ul>
        <button type="button" onClick={() => handleDelete(pm.id)}>
          X
        </button>
      </div>
    </div>
  );
};
