import { RequireAuth } from "@/app/stores/auth";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { ExistingPaymentMethods } from "../ExistingPaymentMethods";
import { PaymentElementCustomCheckoutForm } from "./PaymentElementCustomCheckout.form";
import { Completion } from "../Completion";

const stripePromise = loadStripe(CONFIG.stripePublishableKey);
// "The PaymentMethod provided (paypal) is not allowed for this SetupIntent. Please attach a PaymentMethod of one of the following types: card.
// Alternatively update the allowed payment_method_types for this SetupIntent to include "paypal"."
export const PaymentElementCustomCheckout = () => {
  return (
    <RequireAuth>
      <div className="container">
        <div className="box">
          <h3>Existing Payment Methods</h3>
          <ExistingPaymentMethods />
        </div>
        <div className="box" style={{ padding: 10 }}>
          <Elements
            stripe={stripePromise}
            options={{
              setupFutureUsage: "off_session",
              paymentMethodCreation: "manual",
              paymentMethodTypes: ["card", "ideal", "paypal"],
              loader: "always",
              locale: "es",
              mode: "setup",
              currency: "eur",
              // amount: 3344,
              // more info in https://stripe.com/docs/elements/appearance-api?platform=web
              appearance: {
                theme: "flat",
                variables: {
                  colorText: "#000000",
                  colorTextPlaceholder: "#ABABAB",
                  colorIconCardError: "#FF9081",
                },
              },
            }}
          >
            <PaymentElementCustomCheckoutForm />
            <Completion stripePromise={stripePromise} />
          </Elements>
        </div>
      </div>
    </RequireAuth>
  );
};
