import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { UserInfo } from "./UserInfo";

export const Root = () => {
  return (
    <>
      <div className="container header">
        <h1>Wallets PoC</h1>
        <UserInfo />
        <NavLink
          to={"checkout"}
          className={({ isActive, isPending }) =>
            isActive ? "active" : isPending ? "pending" : ""
          }
        >
          <button type="button" style={{ marginLeft: ".5em" }}>
            Checkout
          </button>
        </NavLink>
        <NavLink
          to={"payment-elements-setup-intent-checkout"}
          className={({ isActive, isPending }) =>
            isActive ? "active" : isPending ? "pending" : ""
          }
        >
          <button type="button" style={{ marginLeft: ".5em" }}>
            Payment Elements Setup intent Checkout
          </button>
        </NavLink>
        <NavLink
          to={"payment-elements-payment-intent-checkout"}
          className={({ isActive, isPending }) =>
            isActive ? "active" : isPending ? "pending" : ""
          }
        >
          <button type="button" style={{ marginLeft: ".5em" }}>
            Payment Elements Payment intent Checkout
          </button>
        </NavLink>
        <NavLink
          to={"payment-elements-custom-flow-checkout"}
          className={({ isActive, isPending }) =>
            isActive ? "active" : isPending ? "pending" : ""
          }
        >
          <button type="button" style={{ marginLeft: ".5em" }}>
            Payment Elements custom Checkout
          </button>
        </NavLink>
        <NavLink
          to={"custom-ideal"}
          className={({ isActive, isPending }) =>
            isActive ? "active" : isPending ? "pending" : ""
          }
        >
          <button type="button" style={{ marginLeft: ".5em" }}>
            Custom iDeal
          </button>
        </NavLink>
      </div>
      <div className="container">
        <Outlet />
      </div>
      <div className="container footer">
        version: <span>{CONFIG.pocVersion || "dev"}</span>
      </div>
    </>
  );
};
