import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { Checkout } from "./views/Checkout";
import { ErrorPage } from "./views/ErrorPage";
import { Login } from "./views/Login";
import { Root } from "./views/Root";
import { PaymentElementCustomCheckout } from "./views/CustomPaymentFlow/PaymentElementCheckout";
import { SetupIntentCheckout } from "./views/PaymentElements/SetupIntent/SetupIntentCheckout";
import { PaymentIntentCheckout } from "./views/PaymentElements/PaymentIntent/PaymentIntentCheckout";
import { CustomIdeal } from "./views/ideal/CustomIdeal";

export const App = () => {
  const router = createHashRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "checkout",
          element: <Checkout />,
        },
        {
          path: "payment-elements-setup-intent-checkout",
          element: <SetupIntentCheckout />,
        },
        {
          path: "payment-elements-payment-intent-checkout",
          element: <PaymentIntentCheckout />,
        },
        {
          path: "payment-elements-custom-flow-checkout",
          element: <PaymentElementCustomCheckout />,
        },
        {
          path: "custom-ideal",
          element: <CustomIdeal />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
