import React from "react";
import { Navigate } from "react-router-dom";
import { create } from "zustand";

type AuthState = {
  info: {
    customerId: string;
    name: string;
    email: string;
  } | null;
};

const readState = (): AuthState =>
  JSON.parse(localStorage.getItem("auth_state") || "null") || { info: null };
const writeState = (s: AuthState) =>
  localStorage.setItem("auth_state", JSON.stringify(s));

export const useAuthStore = create<AuthState>(() => readState());
useAuthStore.subscribe((s) => writeState(s));

export const logout = () => {
  useAuthStore.setState((s) => ({ ...s, info: null }));
};

export const login = async (args: { customerId: string }) => {
  const response = await fetch(`/api/login?customer_id=${args.customerId}`, {
    method: "POST",
  });
  const body = await response.json();
  if (response.status >= 400) {
    throw new Error(body.message);
  }
  useAuthStore.setState((s) => ({
    ...s,
    info: { customerId: body.id, name: body.name, email: body.email },
  }));
};

export const register = async (args: { name: string; email: string }) => {
  const response = await fetch(
    `/api/register?name=${encodeURIComponent(
      args.name
    )}&email=${encodeURIComponent(args.email)}`,
    { method: "POST" }
  );
  const body = await response.json();
  if (response.status >= 400) {
    throw new Error(body.message);
  }
  useAuthStore.setState((s) => ({
    ...s,
    info: { customerId: body.id, name: body.name, email: body.email },
  }));
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const info = useAuthStore((s) => s.info);
  if (info == null) return <Navigate to="/login" replace />;
  return children;
};
