import { useAuthStore } from "@/app/stores/auth";
import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import React, { useEffect, useState } from "react";

export const SetupIntentCompletion = (props: {
  stripePromise: Promise<Stripe | null>;
  automaticCharge: boolean;
}) => {
  const authInfo = useAuthStore((s) => s.info);
  const [messageBody, setMessageBody] = useState<string | JSX.Element>();
  const [automaticPaymentMessageBody, setAutomaticPaymentMessageBody] =
    useState<string | JSX.Element>();
  const [processedSetupIntent, setProcessedSetupIntent] = useState<
    string | undefined
  >();
  const { stripePromise } = props;

  const makeAutomaticCharge = async (paymentMethod: string) => {
    try {
      setAutomaticPaymentMessageBody(undefined);
      const response = await fetch("/api/charge", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          customerId: authInfo!.customerId,
          paymentMethodId: paymentMethod,
          amount: 1000,
          offSession: true,
          return_url: `${window.location.origin}/#/payment-elements-setup-intent-checkout`,
        }),
      });
      const responseData = await response.json();
      setAutomaticPaymentMessageBody(
        <>
          &gt; Setup Intent {responseData.message}:{" "}
          <a
            href={`https://dashboard.stripe.com/test/payments/${responseData.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {responseData.id}
          </a>
          {" Amount charged: 10€"}
        </>
      );
    } catch (e: any) {
      setAutomaticPaymentMessageBody(e.message);
    }
  };

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      setMessageBody(undefined);
      if (!stripe) return;
      const url = new URL(window.location.toString());
      const clientSecret = url.searchParams.get("setup_intent_client_secret");
      if (!clientSecret) return;
      const { error, setupIntent } = await stripe.retrieveSetupIntent(
        clientSecret
      );
      if (processedSetupIntent === clientSecret) return;
      setProcessedSetupIntent(clientSecret);

      setMessageBody(
        error ? (
          `> ${error.message}`
        ) : (
          <>
            &gt; Setup Intent {setupIntent.status}:{" "}
            <a
              href={`https://dashboard.stripe.com/test/setup_intents/${setupIntent.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {setupIntent.id}
            </a>
          </>
        )
      );

      if (setupIntent?.status === "requires_action") {
        // Use Stripe.js to handle the required next action
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { error } = await stripe.handleNextAction({
          clientSecret: clientSecret!,
        });

        if (error) {
          // Show error from Stripe.js in payment form
        } else {
          // Automatic charge using the payment method attached to the SetupIntent
        }
      }

      window.history.replaceState(
        {},
        "",
        window.location.toString().replace(window.location.search, "")
      );

      if (props.automaticCharge && setupIntent?.payment_method) {
        makeAutomaticCharge(setupIntent.payment_method.toString());
      }
    });
  }, [stripePromise]);

  return (
    <>
      {messageBody && (
        <>
          <h1>Setup intent completed. Thank you!</h1>
          <div
            id="messages"
            role="alert"
            style={messageBody ? { display: "block" } : {}}
          >
            {messageBody}
          </div>
        </>
      )}
      {automaticPaymentMessageBody && (
        <>
          <h2>Automatic payment intent completed. Thank you!</h2>
          <div
            id="messages"
            role="alert"
            style={automaticPaymentMessageBody ? { display: "block" } : {}}
          >
            {automaticPaymentMessageBody}
          </div>
        </>
      )}
    </>
  );
};
