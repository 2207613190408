import { RequireAuth, useAuthStore } from "@/app/stores/auth";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";

import { PaymentIntentCheckoutForm } from "./PaymentIntent.form";

import { ExistingPaymentMethods } from "../../ExistingPaymentMethods";
import { PaySomething } from "../PaySomething";
import { PaymentCompletion } from "../PaymentCompletion";

const stripePromise = loadStripe(CONFIG.stripePublishableKey);

export const PaymentIntentCheckout = () => {
  const authInfo = useAuthStore((s) => s.info);
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const createNewPaymentIntent = async () => {
    setLoading(true);
    const response = await fetch(
      `/api/payment-intent?customer_id=${authInfo!.customerId}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    setClientSecret(res.paymentIntent);
    setLoading(false);
  };

  return (
    <RequireAuth>
      <div className="container">
        <div className="box">
          <ExistingPaymentMethods />
          <PaySomething
            stripePromise={stripePromise}
            returnUrl={`${window.location.origin}/#/payment-elements-payment-intent-checkout`}
          />
          <PaymentCompletion stripePromise={stripePromise} />
        </div>
        <div className="box" style={{ padding: 10 }}>
          <p>
            Client secret: <pre>{clientSecret}</pre>
          </p>
          <button
            disabled={loading}
            type="button"
            onClick={createNewPaymentIntent}
          >
            Create new Payment Intent
          </button>
          <PaymentInstrumentTokenizer clientSecret={clientSecret} />
        </div>
      </div>
    </RequireAuth>
  );
};

const PaymentInstrumentTokenizer = ({
  clientSecret,
}: {
  clientSecret: string | undefined;
}) => {
  if (!clientSecret) {
    return <></>;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        // more info in https://stripe.com/docs/elements/appearance-api?platform=web
        appearance: {
          theme: "flat",
          variables: {
            colorText: "#000000",
            colorTextPlaceholder: "#ABABAB",
            colorIconCardError: "#FF9081",
          },
        },
        
      }}
    >
      <PaymentIntentCheckoutForm />
    </Elements>
  );
};
