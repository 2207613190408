import { useEffect, useState } from "react";
import { create } from "zustand";
import { useAuthStore } from "./auth";

export type PaymentMethod = {
  id: string;
  created: number;
  metadata: {
    pay_wallets_poc_origin?: "direct google pay integration";
  };
  type: "card" | "paypal" | "sepa_debit";
};

export type Card = PaymentMethod & {
  type: "card";
  object: string;
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    wallet: { type: string } | null;
  };
};

export type PayPal = PaymentMethod & {
  type: "paypal";
  object: string;
  paypal: {
    fingerprint: string | null;
    payer_id: string | null;
    verified_email: string | null;
  };
};

export type Sepa_debit = PaymentMethod & {
  type: "sepa_debit";
  object: string;
  sepa_debit: {
    bank_code: string;
    branch_code: string;
    country: string;
    fingerprint: string;
    generated_from: {
      charge: string;
      setup_attempt: string | null;
    };
    last4: string;
  };
};

export type PaymentMethodsState = {
  paymentMethods: PaymentMethod[];
};

export const usePaymentMethodsStore = create<PaymentMethodsState>(() => ({
  paymentMethods: [],
}));

async function fetchPaymentMethods(customerId: string) {
  usePaymentMethodsStore.setState((s) => ({
    ...s,
    paymentMethods: [],
  }));
  const response = await fetch(
    `/api/payment_methods?customer_id=${customerId}`
  );
  const responseBody = await response.json();
  if (response.status >= 400) {
    alert(responseBody.message);
    return;
  }
  usePaymentMethodsStore.setState((s) => ({
    ...s,
    paymentMethods: responseBody,
  }));
}

export const usePaymentMethods = () => {
  const authInfo = useAuthStore((s) => s.info);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    if (authInfo?.customerId) fetchPaymentMethods(authInfo.customerId);
  }, [authInfo?.customerId, refreshCounter]);

  return {
    paymentMethods: usePaymentMethodsStore((s) => s.paymentMethods),
    refresh: () => setRefreshCounter((c) => c + 1),
  };
};
