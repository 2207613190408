import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import React, { useEffect, useState } from "react";

export const PaymentCompletion = (props: {
  stripePromise: Promise<Stripe | null>;
}) => {
  const [messageBody, setMessageBody] = useState<string | JSX.Element>();
  const [detailedMessage, setDetailedMessage] = useState<string | undefined>();
  const { stripePromise } = props;

  useEffect(() => {
    setMessageBody(undefined);
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      if (!stripe) return;
      const url = new URL(window.location.toString());
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      if (!clientSecret) return;
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );

      setMessageBody(
        error ? (
          `> ${error.message}`
        ) : (
          <>
            &gt; Payment {paymentIntent.status}:{" "}
            <a
              href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {paymentIntent.id}
            </a>
          </>
        )
      );
      setDetailedMessage(JSON.stringify(paymentIntent, null, 2));

      if (paymentIntent?.status === "requires_action") {
        // Use Stripe.js to handle the required next action
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { error } = await stripe.handleNextAction({
          clientSecret: clientSecret,
        });

        if (error) {
          // Show error from Stripe.js in payment form
        } else {
          // Actions handled, show success message
        }
      }
      window.history.replaceState(
        {},
        "",
        window.location.toString().replace(window.location.search, "")
      );
    });
  }, [stripePromise]);

  return messageBody ? (
    <>
      <h1>Thank you!</h1>
      <div
        id="messages"
        role="alert"
        style={messageBody ? { display: "block" } : {}}
      >
        {messageBody}
      </div>
      {detailedMessage && (
        <pre style={{ overflowX: "scroll" }}>{detailedMessage}</pre>
      )}
    </>
  ) : (
    <></>
  );
};
