import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { login, register } from "../stores/auth"

export const Login = () => {
  return <div className="box login-and-register">
    <LoginForm />
    <div className="separator"></div>
    <RegisterForm />
  </div>
}

const LoginForm = () => {
  const navigate = useNavigate()

  const [customerId, setCustomerId] = useState("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await login({ customerId })
      navigate("/", { replace: true })
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alert((err as any).message)
    }
  }

  return <div className="login">
    <h3>Login</h3>
    <form onSubmit={handleSubmit}>
      <p>
        <input
          type="text"
          value={customerId}
          placeholder="Customer ID"
          onChange={(e) => setCustomerId(e.target.value)} />
      </p>
      <p>
        <button disabled={!customerId}>Login</button>
      </p>
    </form>
  </div>
}

const RegisterForm = () => {
  const navigate = useNavigate()

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await register({ name, email })
    } catch (err) {
      alert((err as { message?: string }).message || "Unknown error")
      return
    }
    navigate("/", { replace: true })
  }

  return <div className="register">
    <h3>Register</h3>
    <form onSubmit={handleSubmit}>
      <p>
        <input
          type="text"
          value={name}
          placeholder="Full name"
          onChange={(e) => setName(e.target.value)} />
      </p>
      <p>
        <input
          type="text"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)} />
      </p>
      <p>
        <button disabled={!name || !email}>Register</button>
      </p>
    </form>
  </div>
}
