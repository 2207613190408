import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CustomIdealForm from "./CustomIdealForm.js";
import { RequireAuth } from "@/app/stores/auth";
import { ExistingPaymentMethods } from "../ExistingPaymentMethods.js";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(CONFIG.stripePublishableKey);

export const CustomIdeal = () => {

  return (
    <RequireAuth>
      <div>
        <div className="box">
          <ExistingPaymentMethods />
        </div>
        <Elements stripe={stripePromise}>
          <CustomIdealForm />
        </Elements>
      </div>
    </RequireAuth>
  );
};
