import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAuthStore } from "@/app/stores/auth";

export const PaymentElementCustomCheckoutForm = () => {
  const authInfo = useAuthStore((s) => s.info);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessage(submitError.message);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          address: {
            country: "ES",
            state: "V",
            postal_code: "46789",
            city: "Valencia",
            line1: "Calle de la Paz",
            line2: "Nº 1, 2º, 3ª",
          },
        },
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      setMessage(error.message!);
      return;
    }

    // Create the SetupIntent
    const res = await fetch(
      `/api/payment_methods/setup_intent?customer_id=${
        authInfo!.customerId
      }&payment_method_id=${paymentMethod.id}`,
      {
        method: "POST",
      }
    );

    const data = await res.json();

    // Handle any next actions or errors. See the Handle any next actions step for implementation.
    await handleServerResponse(data);

    setIsLoading(false);
  };

  const handleServerResponse = async (response: { error: any; status: string; client_secret: any; }) => {
    if (!stripe) return;
    if (response.error) {
      // Show error from server on payment form
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { error } = await stripe.handleNextAction({
        clientSecret: response.client_secret,
      });

      if (error) {
        // Show error from Stripe.js in payment form
      } else {
        // Actions handled, show success message
      }
    } else {
      // No actions needed, show success message
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="box"
      style={{ padding: 15 }}
    >
      <h3>Payment</h3>
      <PaymentElement
        options={{
          wallets: {
            applePay: "auto",
            googlePay: "auto",
          },
          layout: {
            type: "accordion",
            radios: true,
            spacedAccordionItems: false,
          },
          fields: {
            billingDetails: {
              address: "never",
            },
          },
        }}
      />
      <br />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{ margin: 10 }}
      >
        <span id="button-text">{isLoading ? "loading..." : "Pay now"}</span>
      </button>
      {message && (
        <div
          className="box"
          id="payment-message"
          style={{ backgroundColor: "LightPink" }}
        >
          {message}
        </div>
      )}
    </form>
  );
};
