import { assert, Infer, string, type } from "superstruct";
import { create } from "zustand";

type DirectGooglePayState = {
  loaded: boolean;
  ready: boolean;
  readinessProblem: string | null;
  openGooglePaySheet: (opts: {
    country: string;
    currency: string;
    toAuthorizePayment?: {
      description: string;
      total: number;
      items: {
        description: string;
        amount: number;
      }[];
    };
  }) => Promise<
    { cancelled: false; result: GooglePaySheetResult } | { cancelled: true }
  >;
};

const GooglePaySheetResultModel = type({
  id: string(),
});
export type GooglePaySheetResult = Infer<typeof GooglePaySheetResultModel>;

export const useDirectGooglePay = create<DirectGooglePayState>((set, get) => ({
  loaded: false,
  ready: false,
  readinessProblem: null,
  openGooglePaySheet: async (opts) => {
    const s = get();
    if (!s.loaded) throw new Error("Not loaded");
    if (!s.ready) throw new Error("Not ready");

    const client = createClient({
      toAuthorizePayment: !!opts.toAuthorizePayment,
    });
    try {
      const result = await client.loadPaymentData({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: [
                "AMEX",
                "DISCOVER",
                "JCB",
                "MASTERCARD",
                "VISA",
              ],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "stripe",
                "stripe:version": "2018-10-31",
                "stripe:publishableKey": CONFIG.stripePublishableKey,
              },
            },
          },
        ],
        transactionInfo:
          opts.toAuthorizePayment == null
            ? {
              currencyCode: opts.currency,
              totalPriceStatus: "NOT_CURRENTLY_KNOWN",
              totalPrice: undefined!,
            }
            : {
              countryCode: opts.country,
              currencyCode: opts.currency,
              totalPriceStatus: "FINAL",
              totalPrice: formatAmount(opts.toAuthorizePayment.total),
              totalPriceLabel: opts.toAuthorizePayment.description,
              displayItems: opts.toAuthorizePayment.items.map((i) => ({
                label: i.description,
                price: formatAmount(i.amount),
                type: "LINE_ITEM",
              })),
            },
        merchantInfo: {
          merchantId: "01234567890123456789",
          merchantName: "Example Merchant",
        },
        callbackIntents: opts.toAuthorizePayment
          ? ["PAYMENT_AUTHORIZATION"]
          : [],
      });

      const data = JSON.parse(result.paymentMethodData.tokenizationData.token);

      assert(data, GooglePaySheetResultModel);

      return { cancelled: false, result: data };
    } catch (err) {
      if ((err as { statusCode?: string }).statusCode === "CANCELED") {
        return { cancelled: true };
      }
      throw err;
    }
  },
}));

function createClient(opts?: { toAuthorizePayment?: boolean }) {
  return new google.payments.api.PaymentsClient({
    environment: CONFIG.googlePayEnvironment,
    merchantInfo: {
      merchantId: "01234567890123456789",
      merchantName: "Example Merchant",
    },
    paymentDataCallbacks: {
      ...(!opts?.toAuthorizePayment
        ? {}
        : {
          onPaymentAuthorized: () => ({ transactionState: "SUCCESS" }),
        }),
    },
  });
}

function formatAmount(amount: number) {
  const entires = Math.floor(amount / 100);
  const decimals = amount - entires * 100;
  return `${entires.toString()}.${decimals.toString()}`;
}

(window as unknown as { onGooglePayLoaded: () => void }).onGooglePayLoaded =
  () => {
    useDirectGooglePay.setState((s) => ({ ...s, loaded: true }));

    const client = createClient();
    client
      .isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: [
                "AMEX",
                "DISCOVER",
                "JCB",
                "MASTERCARD",
                "VISA",
              ],
            },
          },
        ],
      })
      .then(
        (response) => {
          if (response.result) {
            useDirectGooglePay.setState((s) => ({
              ...s,
              ready: true,
              readinessProblem: null,
            }));
          } else {
            useDirectGooglePay.setState((s) => ({
              ...s,
              ready: true,
              readinessProblem:
                "Google Pay reported not available here. You can try, but it will fail.",
            }));
          }
        },
        (err: unknown) => {
          useDirectGooglePay.setState((s) => ({
            ...s,
            ready: false,
            readinessProblem:
              "Error while asking for Google Pay readyness: " +
                (err as { message?: string }).message || "Unknown error",
          }));
          console.error(err);
        }
      );
  };
