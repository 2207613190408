import { useEffect, useState } from "react";
import { create } from "zustand";
import { useAuthStore } from "./auth";

export type CardPaymentMethodsState = {
  paymentMethods: {
    id: string;
    created: number;
    card: {
      brand: string;
      exp_month: number;
      exp_year: number;
      last4: string;
      wallet: { type: string } | null;
    };
    metadata: {
      pay_wallets_poc_origin?: "direct google pay integration";
    };
  }[];
};

export const useCardPaymentMethodsStore = create<CardPaymentMethodsState>(() => ({
  paymentMethods: [],
}));

async function fetchCardPaymentMethods(customerId: string) {
  useCardPaymentMethodsStore.setState((s) => ({
    ...s,
    paymentMethods: [],
  }));
  const response = await fetch(
    `/api/payment_methods?type=card&customer_id=${customerId}`
  );
  const responseBody = await response.json();
  if (response.status >= 400) {
    alert(responseBody.message);
    return;
  }
  useCardPaymentMethodsStore.setState((s) => ({
    ...s,
    paymentMethods: responseBody,
  }));
}

export const useCardPaymentMethods = () => {
  const authInfo = useAuthStore((s) => s.info);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    if (authInfo?.customerId) fetchCardPaymentMethods(authInfo.customerId);
  }, [authInfo?.customerId, refreshCounter]);

  return {
    paymentMethods: useCardPaymentMethodsStore((s) => s.paymentMethods),
    refresh: () => setRefreshCounter((c) => c + 1),
  };
};
