import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import React, { useEffect, useState } from "react";
import { useAuthStore } from "../stores/auth";

export const Completion = (props: {
  stripePromise: Promise<Stripe | null>;
}) => {
  const authInfo = useAuthStore((s) => s.info);
  const [messageBody, setMessageBody] = useState<string | JSX.Element>();
  const { stripePromise } = props;

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      if (!stripe) return;
      const url = new URL(window.location.toString());
      const clientSecret = url.searchParams.get("setup_intent_client_secret");
      const { error, setupIntent } = await stripe.retrieveSetupIntent(clientSecret!);

      setMessageBody(
        error ? (
          `> ${error.message}`
        ) : (
          <>
            &gt; Payment {setupIntent.status}:{" "}
            <a
              href={`https://dashboard.stripe.com/test/payments/${setupIntent.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {setupIntent.id}
            </a>
          </>
        )
      );

      const response = await fetch("/api/charge", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          customerId: authInfo!.customerId,
          paymentMethodId: setupIntent?.payment_method,
          amount: 3589,
          offSession: true,
        }),
      });
    });
  }, [stripePromise]);

  return messageBody ? (
    <>
      <h1>Thank you!</h1>
      <div
        id="messages"
        role="alert"
        style={messageBody ? { display: "block" } : {}}
      >
        {messageBody}
      </div>
    </>
  ) : (
    <></>
  );
};
