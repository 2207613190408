import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { usePaymentMethods } from "@/app/stores/paymentMethods";

type PaymentElementLayoutType = "accordion" | "accordionRadio" | "tabs";

export const PaymentIntentCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { refresh: refreshPaymentMethods } = usePaymentMethods();

  const [message, setMessage] = useState<string | null>(null);
  const [paymentElementLayoutType, setPaymentElementLayoutType] =
    useState<PaymentElementLayoutType>("accordionRadio");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/#/payment-elements-payment-intent-checkout`,
      },
    });

    console.log("[DEBUG] paymentIntent:", paymentIntent);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setMessage(error.message || "An unexpected error occurred.");
      setIsLoading(false);
      return;
    }
    refreshPaymentMethods();
    setIsLoading(false);
  };

  return (
    <>
      <h3>Payment</h3>
      <h4>Payment Element Layout</h4>
      <select
        onChange={(e) => {
          setPaymentElementLayoutType(
            e.target.value as PaymentElementLayoutType
          );
        }}
      >
        <option value="accordion">Accordion</option>
        <option value="accordionRadio" selected>
          Accordion with radio buttons
        </option>
        <option value="tabs">Tabs</option>
      </select>

      <form onSubmit={handleSubmit} className="box" style={{ padding: 15 }}>
        <PaymentElement
          options={{
            layout: {
              type:
                paymentElementLayoutType === "accordionRadio"
                  ? "accordion"
                  : paymentElementLayoutType,
              radios: paymentElementLayoutType === "accordionRadio",
              spacedAccordionItems: false,
            },
            fields: {
              billingDetails: {
                address: "auto",
              },
            },
          }}
        />
        <br />
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          style={{ margin: 10 }}
        >
          <span id="button-text">{isLoading ? "loading..." : "Pay now"}</span>
        </button>
        {message && (
          <div
            className="box"
            id="payment-message"
            style={{ backgroundColor: "LightPink" }}
          >
            {message}
          </div>
        )}
      </form>
    </>
  );
};
