import React from "react";
import { useNavigate } from "react-router-dom";
import { logout, useAuthStore } from "../stores/auth";

export const UserInfo = () => {
  const navigate = useNavigate();
  const { info } = useAuthStore();

  return (
    <div className="user-info">
      {info ? (
        <>
          <span>
            {info.name} ({info.email})
          </span>
          <br />
          <span style={{ fontFamily: "monospace" }}>{info.customerId} </span>
          <a
            style={{ fontFamily: "monospace" }}
            href={CONFIG.stripeDashboardUrl + "/customers/" + info.customerId}
          >
            Stripe
          </a>
          <button
            type="button"
            style={{ marginLeft: ".5em" }}
            onClick={() => logout()}
          >
            Logout
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            style={{ marginLeft: ".5em" }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </>
      )}
    </div>
  );
};
