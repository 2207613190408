import React, { useEffect, useState } from "react";
import {
  Elements,
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, PaymentRequest } from "@stripe/stripe-js";
import { RequireAuth, useAuthStore } from "../stores/auth";
import { useCardPaymentMethods } from "../stores/cardPaymentMethods";
import { create } from "zustand";
import { useDirectGooglePay } from "../apis/directGooglePay";

const stripePromise = loadStripe(CONFIG.stripePublishableKey);

const useSelectedPaymentMethodId = create<{ id: string | null }>(() => ({
  id: null,
}));

export const Checkout = () => {
  return (
    <RequireAuth>
      <Elements stripe={stripePromise}>
        <AddPaymentMethod />
        <AddPaymentMethodWithDirectGooglePay />
        <ExistingPaymentMethods />
        <PaySomething />
      </Elements>
    </RequireAuth>
  );
};

const AddPaymentMethod = () => {
  const { refresh: refreshPaymentMethods } = useCardPaymentMethods();
  const authInfo = useAuthStore((s) => s.info);
  const stripe = useStripe();
  const [state, setState] = useState<{
    initialized: boolean;
    stripeLoading: boolean;
    canMakePayment: boolean | Record<string, boolean>;
    paymentRequest: PaymentRequest | null;
  }>({
    initialized: false,
    stripeLoading: false,
    canMakePayment: false,
    paymentRequest: null,
  });

  useEffect(() => {
    if (!stripe) return;
    setState((s) => ({ ...s, initialized: true, stripeLoading: true }));

    const paymentRequest = stripe.paymentRequest({
      country: "ES",
      currency: "eur",
      total: {
        label: "Total del pedido",
        amount: 20000,
      },
      displayItems: [
        {
          label: "Chaqueta verde",
          amount: 12000,
        },
        {
          label: "Pantalones vaqueros",
          amount: 10000,
        },
        {
          label: "Saldo descontado",
          amount: -2000,
        },
      ],
    });

    paymentRequest.canMakePayment().then((result) => {
      setState((s) => ({
        ...s,
        stripeLoading: false,
        canMakePayment: result || false,
        paymentRequest: result ? paymentRequest : null,
      }));
    });

    // // With Setup Intent
    // paymentRequest.on('paymentmethod', async (ev) => {
    //   const customerId = authInfo!.customerId
    //   const response = await fetch(`/api/payment_methods/setup_intent?customer_id=${customerId}&payment_method_id=${ev.paymentMethod.id}`, { method: 'POST' })
    //   const responseBody = await response.json();
    //   if (response.status >= 400) {
    //     ev.complete('fail')
    //     alert(responseBody.message)
    //     return
    //   }
    //   console.log(responseBody)
    //   refreshPaymentMethods()
    //   ev.complete('success')
    // })

    // With Attach
    paymentRequest.on("paymentmethod", async (ev) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const customerId = authInfo!.customerId;
      const response = await fetch(
        `/api/payment_methods/attach?customer_id=${customerId}&payment_method_id=${ev.paymentMethod.id}`,
        { method: "POST" }
      );
      const responseBody = await response.json();
      if (response.status >= 400) {
        ev.complete("fail");
        alert(responseBody.message);
        return;
      }
      console.log(responseBody);
      refreshPaymentMethods();
      ev.complete("success");
    });
  }, [stripe]);

  return (
    <>
      <div className="box">
        <h3>Add Payment Method</h3>
        <ul style={{ fontFamily: "monospace" }}>
          <li>Initialized: {state.initialized.toString()}</li>
          <li>Stripe loading: {state.stripeLoading.toString()}</li>
          <li>Can make payment: {JSON.stringify(state.canMakePayment)}</li>
        </ul>
        <p>
          {state.canMakePayment &&
            Object.entries(state.canMakePayment).map(([name, enabled]) => (
              <span
                className={`wallet-availability ${
                  enabled ? "is-available" : ""
                }`}
              >
                {name}
              </span>
            ))}
        </p>
        <PaymentRequestButton paymentRequest={state.paymentRequest} />
      </div>
    </>
  );
};

const PaymentRequestButton = (props: {
  paymentRequest: PaymentRequest | null;
}) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (props.paymentRequest == null) setVisible(false);
  }, [props.paymentRequest]);

  return (
    <p
      className={visible ? "animate__animated animate__flipInX" : ""}
      style={{ opacity: visible ? 1 : 0 }}
    >
      {props.paymentRequest && (
        <PaymentRequestButtonElement
          options={{ paymentRequest: props.paymentRequest }}
          onReady={() => setVisible(true)}
        />
      )}
    </p>
  );
};

const CARD_LOGOS: Record<string, string | undefined> = {
  visa: "https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/logo/visa.svg",
  mastercard:
    "https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/logo/mastercard.svg",
};
const getCardLogo = (brand: string) => {
  return CARD_LOGOS[brand] || null;
};

const AddPaymentMethodWithDirectGooglePay = () => {
  const { refresh: refreshPaymentMethods } = useCardPaymentMethods();
  const authInfo = useAuthStore((s) => s.info);
  const { loaded, ready, readinessProblem, openGooglePaySheet } =
    useDirectGooglePay();
  const [includePayment, setIncludePayment] = useState(false);

  const handleClick = async () => {
    if (!authInfo) return;

    try {
      const response = await openGooglePaySheet({
        country: "ES",
        currency: "EUR",
        toAuthorizePayment: !includePayment
          ? undefined
          : {
            description: "Total del pedido",
            total: 20000,
            items: [
              {
                description: "Chaqueta verde",
                amount: 12000,
              },
              {
                description: "Pantalones vaqueros",
                amount: 10000,
              },
              {
                description: "Saldo descontado",
                amount: -2000,
              },
            ],
          },
      });
      if (response.cancelled) return;

      await fetch(
        `/api/payment_methods/from_card_token?card_token=${response.result.id}&customer_id=${authInfo.customerId}`,
        { method: "POST" }
      );
      refreshPaymentMethods();
    } catch (err) {
      alert(
        `Error: ${(err as { message?: string }).message || "Unknown error"}`
      );
      console.log(err);
    }
  };

  return (
    <div className="box">
      <h3>Add Payment Method with Direct Google Pay</h3>
      <ul style={{ fontFamily: "monospace" }}>
        <li>Loaded: {loaded.toString()}</li>
        <li>Ready: {ready.toString()}</li>
      </ul>
      <p>
        <input
          type="checkbox"
          id="includepayment"
          checked={includePayment}
          onChange={(e) => setIncludePayment(e.target.checked)}
        />
        <label htmlFor="includepayment">
          Include payment info on tokenization
        </label>
      </p>
      {ready && (
        <p>
          <button type="button" onClick={handleClick}>
            Tokenize using Google Pay
          </button>
        </p>
      )}
      {readinessProblem != null && (
        <p style={{ color: "red" }}>{readinessProblem}</p>
      )}
    </div>
  );
};

const ExistingPaymentMethods = () => {
  const { paymentMethods, refresh: refreshPaymentMethods } =
    useCardPaymentMethods();
  const selectedPaymentMethodId = useSelectedPaymentMethodId((s) => s.id);
  const authInfo = useAuthStore((s) => s.info);

  useEffect(() => {
    useSelectedPaymentMethodId.setState({ id: null });
  }, []);

  const handleDelete = async (paymentMethodId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const customerId = authInfo!.customerId;
    const response = await fetch(
      `/api/payment_methods?customer_id=${customerId}&payment_method_id=${paymentMethodId}`,
      { method: "DELETE" }
    );
    const responseBody = await response.json();
    if (response.status >= 400) {
      alert(responseBody.message);
      return;
    }
    refreshPaymentMethods();
  };

  return (
    <div className="box">
      <h3>
        Existing Payment Methods{" "}
        <button onClick={refreshPaymentMethods}>Refresh</button>
      </h3>

      <form>
        {paymentMethods
          .sort((a, b) => b.created - a.created)
          .map((pm) => {
            const logo = getCardLogo(pm.card.brand);
            return (
              <div className="credit-card">
                <input
                  type="radio"
                  id={pm.id}
                  name="pm"
                  onChange={(e) =>
                    useSelectedPaymentMethodId.setState({ id: e.target.id })
                  }
                  checked={pm.id === selectedPaymentMethodId}
                />
                <label className="info" htmlFor={pm.id}>
                  {logo && <img src={logo} alt="" />}
                  <div className="digits">
                    <span className="fill">###</span>
                    <span className="tail">{pm.card.last4}</span>
                  </div>
                  <div className="expiration">
                    <span>
                      {pm.card.exp_month.toString().padStart(2, "0")}/
                      {pm.card.exp_year}
                    </span>
                  </div>
                </label>
                <div className="meta">
                  <ul>
                    <li>id: {pm.id}</li>
                    <li>
                      wallet: {pm.card.wallet?.type || "none"}{" "}
                      {pm.metadata.pay_wallets_poc_origin ===
                      "direct google pay integration"
                        ? "(direct)"
                        : ""}
                    </li>
                  </ul>
                  <button type="button" onClick={() => handleDelete(pm.id)}>
                    X
                  </button>
                </div>
              </div>
            );
          })}
      </form>
    </div>
  );
};

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type PaymentMethod = ArrayElement<
  ReturnType<typeof useCardPaymentMethods>["paymentMethods"]
>;

const PaySomething = () => {
  const selectedPaymentMethodId = useSelectedPaymentMethodId((s) => s.id);
  const authInfo = useAuthStore((s) => s.info);
  const { paymentMethods } = useCardPaymentMethods();
  const [amount, setAmount] = useState(1);
  const [offSession, setOffSession] = useState(false);
  const [response, setResponse] = useState<{ id: string } | null>(null);
  const selectedPaymentMethod = paymentMethods.reduce((result, current) => {
    if (current.id === selectedPaymentMethodId) return current;
    return result;
  }, null as PaymentMethod | null);

  const canPay = authInfo?.customerId && selectedPaymentMethod;

  const handlePay = async () => {
    if (!canPay) return;
    setResponse(null);

    const response = await fetch("/api/charge", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        customerId: authInfo.customerId,
        paymentMethodId: selectedPaymentMethod.id,
        amount,
        offSession,
      }),
    });
    const responseBody = await response.json();
    if (response.status >= 400) {
      alert(responseBody.message);
      return;
    }
    setResponse(responseBody);
  };

  return (
    <>
      <div className="box">
        <h3>Pay Something</h3>
        <p>
          <input
            type="checkbox"
            id="off_session"
            checked={offSession}
            onChange={(e) => setOffSession(e.target.checked)}
          />
          <label htmlFor="off_session">Off session</label>
          <br />

          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
          />
          <br />

          <button onClick={handlePay} disabled={!canPay}>
            Pay
          </button>
        </p>
        {response && (
          <pre style={{ overflowX: "scroll" }}>
            {JSON.stringify(response, null, 2)}
          </pre>
        )}
      </div>
    </>
  );
};
